

import {
  Component, Watch, Prop, Vue,
} from 'vue-property-decorator'
import { mapState } from 'vuex'
import _ from 'lodash'
import store from '@/store'

@Component({})
export default class FilterButton extends Vue {
  constructor() {
    super()
    this.activated = false
  }

  @Prop() private label!: string

  @Prop() isActive!: boolean

  private activated: boolean

  toggle() {
    // if (store.state.filtersDeactivated === true) {
    //   store.dispatch('set', { key: 'filtersDeactivated', data: false })
    // } else this.activated = !this.activated
    this.activated = !this.activated
  }
}
